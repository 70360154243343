<template>
  <div class="result-result-items">
    <Wrapper>
      <ExamResultItemsTable
        :exam-id="examId"
        :is-partial="false"
        @click-row="showItemSidebar()"
      />
    </Wrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ExamResultItemsTable from '@/components/ExamResultItemsTable'

export default {
  name: 'ExamResultsItems',

  components: {
    ExamResultItemsTable,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => vm.$emit('select-tab', vm.$route.name))
  },

  props: {
    examId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      selectedItem: {
        order: -1,
        id: 0,
      },
      sidebarVisible: false,
      sidebarHeightOffset: 0,
      sidebarTop: 0,
      itemsPerformance: [],
    }
  },

  computed: {
    ...mapGetters(['isRankedAssessment', 'exam', 'isGradedByIrt']),

    categoryType() {
      return this.exam.name === 'Matemática'
        ? 'Unidade temática'
        : 'Campo de atuação'
    },

    hasPreviousItem() {
      return this.selectedItem.order > 1
    },

    hasNextItem() {
      return this.selectedItem.order < this.itemsPerformance.length
    },
  },

  beforeDestroy() {
    this.closeItemSidebar()
  },

  methods: {
    showItemSidebar({ item, itemsPerformance }) {
      if (this.loading) {
        return
      }

      document.querySelector('body').style.overflow = 'hidden'
      if (this.$mq === 'x_large') {
        this.setSidebarPosition()
      }
      this.selectedItem = item
      this.itemsPerformance = itemsPerformance
      this.sidebarVisible = true
    },

    setSidebarPosition() {
      const SCROLL_TOP_LIMIT = 56
      const scrollTop = window.scrollY - SCROLL_TOP_LIMIT
      const header = document.querySelector('.exam-result .header')
      const initialOffset = header.clientHeight
      if (scrollTop <= initialOffset) {
        this.sidebarHeightOffset = initialOffset - scrollTop
        this.sidebarTop = 0
      } else {
        this.sidebarHeightOffset = 0
        this.sidebarTop = scrollTop - initialOffset
      }
    },

    closeItemSidebar() {
      this.selectedItem = {
        order: -1,
        id: 0,
      }
      this.sidebarVisible = false
      document.querySelector('body').style.overflow = 'auto'
    },

    back() {
      if (!this.hasPreviousItem) {
        return
      }

      this.selectedItem = this.itemsPerformance.find(
        (item) => item.order === this.selectedItem.order - 1,
      )
    },

    next() {
      if (!this.hasNextItem) {
        return
      }

      this.selectedItem = this.itemsPerformance.find(
        (item) => item.order === this.selectedItem.order + 1,
      )
    },

    getPercentage(grade) {
      const PERCENTAGE_MULTIPLIER = 100

      return Math.round(PERCENTAGE_MULTIPLIER * grade)
    },
  },
}
</script>

<style lang="sass" scoped>
.result-result-items
  padding: $size-l 0
</style>
